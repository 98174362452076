<template>
  <div class="add_teacher">
    <div class="title">添加/编辑景点</div>
    <br />
    <hr />
    <br />
    <el-card class="box-card">
      <el-form
        ref="addFromRef"
        :model="addFrom"
        :rules="addFromRules"
        label-width="110px"
      >
        <el-form-item label="景点名称:" prop="scenicName">
          <el-input v-model="addFrom.scenicName" style="width: 50%"></el-input>
        </el-form-item>

        <el-form-item label="轮播图" prop="imgUrl">
          <send-image
            type="more"
            @addImageStrs="addImageStrs"
            :images="addFrom.imgUrl"
          ></send-image>

          <i>每张图片不能超过500K，建议尺寸：280*140px</i>
        </el-form-item>

        <el-form-item label="景点详情:" prop="details">
          <VueUeditorWrap
            v-model="addFrom.details"
            :config="editorConfig"
            ref="myTextEditor"
          ></VueUeditorWrap>
        </el-form-item>

        <el-form-item>
          <el-button type="success" @click="submitForm">保存</el-button>
          <el-button type="primary" @click="goBack">返回</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import editorConfig from "../../utils/editorConfig.js";
import VueUeditorWrap from "vue-ueditor-wrap";
import sendImage from "../../components/sendImage.vue";
import { scenicListAdd, scenicListDetails } from "../../api/travel.js";
export default {
  components: { VueUeditorWrap, sendImage },
  data() {
    return {
      checkList: [],
      addFrom: {
        scenicId: 0,
        daysId: 0,
        scenicName: "",
        imgUrl: "",
        details: "",
      },
      editorConfig,
      addFromRules: {
        scenicName: [{ required: true, message: "请输入景点名称", trigger: "blur" }],
        imgUrl: [{ required: true, message: "请上传景点图片", trigger: "blur" }],
        details: [{ required: true, message: "请输入景点详情", trigger: "blur" }],
      },
    };
  },
  created() {
    this.addFrom.daysId = this.$route.query.daysId || 0;
    this.addFrom.scenicId = this.$route.query.scenicId || 0;
    if (this.addFrom.scenicId) {
      this.getDetails();
    }
  },
  methods: {
    getDetails() {
      scenicListDetails({ scenicId: this.addFrom.scenicId }).then((res) => {
        if (res.data.code == 0) {
          this.addFrom = res.data.data;

          this.addFrom.imgUrl = this.addFrom.imgUrl.join(",");
          console.log(this.checkList);
        }
      });
    },
    handleAvatarSuccess(res, file, type) {
      this.addFrom.headImgUrl = file.response.data[0].src;
    },
    addImageStrs(arr) {
      this.addFrom.imgUrl = arr.join(",");
    },
    submitForm() {
      console.log(this.checkList);

      //   console.log(params);
      //   return;
      this.$refs.addFromRef.validate(async (validate) => {
        if (!validate) return;
        if (this.isSubmit) return;

        this.isSubmit = true;
        const { data } = await scenicListAdd(this.addFrom);
        this.isSubmit = false;
        if (data.code == 0) {
          this.goBack();
          this.$message.success(data.msg);
        } else {
          this.$message.warning(data.msg);
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.avatar-uploader-icon {
  border: 1px dashed #eee;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
}
.box-card {
  padding-bottom: 50px;
}
</style>
